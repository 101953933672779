<template>
  <PublicLayout>
    <div
      class="container"
      :class="device + '-container'"
    >
      <img
        v-if="!mq.phone && !mq.projectSizeLimit2Minus"
        src="@/assets/img_intro.png"
        class="img-intro"
        :class="device + '-img-intro'"
        alt=""
      >

      <div
        class="content"
        :class="device + '-content'"
      >
        <h1 v-t="'Project.title'" />
        <h3 v-t="'Project.line1'" />
        <div class="row">
          <div class="column">
            <p v-t="'Project.line2'" />
            <br>
            <p v-t="'Project.line3'" />
          </div>
          <div
            class="column"
            :style="mq.phone ? '' : 'margin-left: 20px'"
          >
            <ul>
              <li><strong>{{ $t('Project.line4') }}</strong>{{ $t('Project.line4bis') }}</li>
              <li><strong>{{ $t('Project.line5') }}</strong>{{ $t('Project.line5bis') }}</li>
              <li><strong>{{ $t('Project.line6') }}</strong>{{ $t('Project.line6bis') }}</li>
            </ul>
          </div>
        </div>
        <p v-t="'Project.line7'" />
      </div>

      <div
        class="sponsor"
        :class="device + '-sponsor'"
      >
        <div class="column column1">
          <h3 v-t="'Project.line8'" />
          <p v-t="'Project.line9'" />
        </div>
        <div class="arrow" />
        <div class="column column2">
          <img
            src="@assets/partners/logo_interreg.png"
            alt="logo interreg"
          >
          <button
            v-t="'Project.partnerButtonLabel'"
            @click="redirectPartners"
          />
        </div>
      </div>
      <br>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'

export default {
  name: 'Prodect',
  components: {
    PublicLayout
  },
  inject: ['mq'],
  computed: {
    device () {
      console.log(this.mq)
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    }
  },
  methods: {
    redirectPartners () {
      this.$router.push({ name: 'partners' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  height: 100%;
  background-color: $public-bg;
  color: $color-light-text;
  padding: 40px 5% 0 5%;
  overflow: hidden;

  .img-intro {
    position: absolute;
  }

  .desktop-img-intro {
    top: -25px;
    height: 800px;
    left: -150px;
  }

  .limit1-img-intro, .limit2-img-intro {
    top: -25px;
    left: -110px;
    height: 600px;
  }

  .content {
    margin-left: auto;
    width: fit-content;
    max-width: 832px;
    position: relative;
    min-height: 600px;

    h1 {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 50px;
      z-index: 1;
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 34px;
      z-index: 1;
    }

    p, li{
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 27px;
      text-align: justify;
    }

    ul {
      padding-left: 30px;
      list-style: disc outside none;
    }

    li{
      display: list-item;
    }

    strong {
      font-weight: bold;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 10px;

      .column {
        min-width: 300px;
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .content {
      margin-left: 600px;
      max-width: none;
    }
  }

  .limit2-content {
    margin-right: auto;
  }

  .limit1-content, .limit2-content {
    margin-left: auto;
    max-width: 700px;

    h1 {
      font-size: 35px;
      line-height: 40px;
    }

    h3 {
      font-size: 20px;
      line-height: 35px;
    }

    p, li{
      font-size: 15px;
      line-height: 25px;
    }
  }

  .phone-content {
    position: relative;
    width: 100%;

    h1 {
      font-size: 2em;
      margin-top: 30px;
      line-height: 40px;
      text-align: center;
    }

    h3 {
      font-size: 1.15em;
      line-height: 30px;
    }

    p, li{
      font-size: 1.025em;
      line-height: 23px;
      text-align: left;
    }

    .row {
      display: block;
      width: 100%;
      margin-bottom: 10px;

      .column {
        min-width: 0;
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  .sponsor {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    height: 210px;
    background-color: $color-body-bg;
    border-radius: 6px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    display: flex;

    .column1 {
      background-color: $color-menu-bg;

      h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
      }

      p{
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    .column2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      button{
        background-color: white;
        border-width: 1px;
        height: 48px;
        width: 194px;
        cursor: pointer;
      }
    }
  }

  .desktop-sponsor, .limit1-sponsor, .limit2-sponsor {

    .column {
      width: 50%;
      padding: 20px;
    }

    .arrow {
      position: absolute;
      height: 100%;
      left: 50%;
      border-top: 105px solid transparent;
      border-bottom: 105px solid transparent;
      border-left: 70px solid $color-menu-bg;
    }
  }

  .phone-sponsor {
    flex-direction: column;
    height: 600px;

    .column {
      width: 100%;
      height: 50%;
      padding: 20px;
    }

    .arrow {
      position: absolute;
      width: 100%;
      top: 50%;
      // TODO compute percentage
      border-left: 140px  solid transparent;
      border-right: 140px solid transparent;
      border-top: 20px solid $color-menu-bg;
    }
  }
}

.phone-container {
  padding: 0 20px;
}
</style>
